import React, { useState } from "react"
import styled from "@emotion/styled"

// import one from "../../../images/ordering-process/1.svg"
// import two from "../../../images/ordering-process/2.svg"
// import three from "../../../images/ordering-process/3.svg"
// import four from "../../../images/ordering-process/4.svg"
// import five from "../../../images/ordering-process/5.svg"
// import six from "../../../images/ordering-process/6.svg"
// import artwork from "../../../images/ordering-process/artwork.svg"
// import delivery from "../../../images/ordering-process/delivery.svg"
// import designFile from "../../../images/ordering-process/design-file.svg"
// import getAQuote from "../../../images/ordering-process/get-a-quote.svg"
// import production from "../../../images/ordering-process/production.svg"
// import sample from "../../../images/ordering-process/sample.svg"
// import leftDottedArrow from "../../../images/ordering-process/left-dotted-arrow.svg"
// import rightDottedArrow from "../../../images/ordering-process/right-dotted-arrow.svg"
// import downDottedArrow from "../../../images/ordering-process/down-dotted-arrow.svg"
import orderingProcess from "../../../images/ordering-process/ordering-process.svg"

const StyledOrderingProcess = styled.div`
  padding: 5vw 12vw;
  background-color: #f1f2f2;
  .heading {
    color: black;
    font-size: 1.7vw;
    margin-bottom: 5vw;
  }
  .image-wrapper {
    margin-bottom: 4vw;
  }
  .journey-heading {
    font-size: 1.1vw;
    font-weight: 600;
  }
  .journey-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6vw;
    .journey-item {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 1vw;
      font-size: 1vw;
      color: #58595b;
    }
  }
`

const OrderingProcess = () => {
  return (
    <StyledOrderingProcess>
      <div className="content">
        <h1 className="heading">Ordering Process</h1>
        <div className="image-wrapper">
          <img src={orderingProcess} alt="ordering process" />
        </div>
        <div className="journey-heading">
          <p>But first, let us guide you through the journey itself</p>
        </div>
        <div className="journey-wrapper">
          <div className="journey-item">
            <div className="number">1.</div>
            <div className="journey-content">
              Our process starts by reviewing our client’s initial designs for
              text proofing and colour management purposes, before printing the
              final design through our Epson Digital Printer.
            </div>
          </div>
          <div className="journey-item">
            <div className="number">2.</div>
            <div className="journey-content">
              After the artwork is approved by our standards, we move on to
              produce the label plates through our Esko CDI plate-making
              machine. By doing the plate designs in-house, we are able to offer
              a shorter wait time of up to 2 to 3 hours and better security of
              the client’s designs, as we do not hand them to a third party.
            </div>
          </div>
          <div className="journey-item">
            <div className="number">3.</div>
            <div className="journey-content">
              The plates are then printed through our two Gallus Flexo printing
              machines, which offers self-lamination, screen printing, cold
              foiling, multilayering of materials and varnish.
            </div>
          </div>
        </div>
      </div>
    </StyledOrderingProcess>
  )
}

export default OrderingProcess
