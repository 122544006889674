import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/Services"

const ServicesPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Services" keywords={[`BMR`, `labels`]} />
    <Services />
  </Layout>
)

export default ServicesPage
