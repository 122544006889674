import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { CarouselProvider, Slider, Slide, Dot } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

const StyledFilm = styled.div`
  padding: 2.5vw 2.5vw;
  padding-bottom: 0;
  padding-right: 0;
  .paper-heading {
    color: #6d6e71;
    font-size: 1.3vw;
    font-weight: 600;
    margin-bottom: 4vw;
  }
  .paper-content {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 2vw;
    .paper-menu {
      ul {
        list-style-type: none;
        margin: 0;
        li {
          font-family: "AKZIDENZ GROTESK EXTENDED";
          font-size: 0.8vw;
          color: #a7a9ac;
          margin-bottom: 1.5vw;
          cursor: pointer;
        }
        .selected {
          color: #6d6e71;
          font-weight: 600;
          transition: 0.1s;
          text-decoration: underline;
        }
      }
    }
    .paper-image {
      position: relative;
      img {
        margin: 0;
      }
      .pagination {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .dot {
          margin: 0.3rem;
          background-color: transparent;
          border-radius: 50%;
          border: solid 1px white;
          width: 9px;
          height: 9px;
          padding: 0;
          &:hover {
            cursor: pointer;
          }
        }
        .active {
          background-color: white;
        }
      }
    }
    .paper-characteristics {
      .characteristics-heading {
        font-family: "Akzidenz Grotesk Extended";
        font-size: 0.8vw;
        font-weight: 600;
        color: #6d6e71;
        margin-bottom: 1vw;
      }
      ul {
        list-style-type: square;
        color: #6d6e71;
        transform: translateX(-0.4vw);
        li {
          margin-bottom: 0.3vw;
          font-size: 0.85vw;
        }
      }
    }
  }
`

const Film = () => {
  const [selected, setSelected] = useState(0)
  const [index, setIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      peWhite1: file(
        relativePath: { eq: "materials/film-materials/pe-white/pe-white-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peWhite2: file(
        relativePath: { eq: "materials/film-materials/pe-white/pe-white-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peWhite3: file(
        relativePath: { eq: "materials/film-materials/pe-white/pe-white-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peClear1: file(
        relativePath: { eq: "materials/film-materials/pe-clear/pe-clear-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peClear2: file(
        relativePath: { eq: "materials/film-materials/pe-clear/pe-clear-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      peClear3: file(
        relativePath: { eq: "materials/film-materials/pe-clear/pe-clear-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppWhite1: file(
        relativePath: { eq: "materials/film-materials/pp-white/pp-white-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppWhite2: file(
        relativePath: { eq: "materials/film-materials/pp-white/pp-white-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppWhite3: file(
        relativePath: { eq: "materials/film-materials/pp-white/pp-white-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppClear1: file(
        relativePath: { eq: "materials/film-materials/pp-clear/pp-clear-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppClear2: file(
        relativePath: { eq: "materials/film-materials/pp-clear/pp-clear-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppClear3: file(
        relativePath: { eq: "materials/film-materials/pp-clear/pp-clear-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppSilver1: file(
        relativePath: {
          eq: "materials/film-materials/pp-silver/pp-silver-1.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppSilver2: file(
        relativePath: {
          eq: "materials/film-materials/pp-silver/pp-silver-2.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ppSilver3: file(
        relativePath: {
          eq: "materials/film-materials/pp-silver/pp-silver-3.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perlized1: file(
        relativePath: { eq: "materials/film-materials/perlized/perlized-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perlized2: file(
        relativePath: { eq: "materials/film-materials/perlized/perlized-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      perlized3: file(
        relativePath: { eq: "materials/film-materials/perlized/perlized-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledFilm>
      <h1 className="paper-heading">FILM MATERIALS</h1>
      <div className="paper-content">
        <div className="paper-menu">
          <ul>
            <li
              className={selected === 0 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(0)
              }}
            >
              PE WHITE
            </li>
            <li
              className={selected === 1 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(1)
              }}
            >
              PE CLEAR
            </li>
            <li
              className={selected === 2 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(2)
              }}
            >
              PP WHITE
            </li>
            <li
              className={selected === 3 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(3)
              }}
            >
              PP CLEAR
            </li>
            <li
              className={selected === 4 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(4)
              }}
            >
              PP SILVER
            </li>
            <li
              className={selected === 5 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(5)
              }}
            >
              PERLIZED
            </li>
          </ul>
        </div>
        <div className="paper-image">
          {selected === 0 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.peWhite1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.peWhite2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.peWhite3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 1 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.peClear1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.peClear2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.peClear3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 2 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.ppWhite1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.ppWhite2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.ppWhite3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 3 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.ppClear1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.ppClear2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.ppClear3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 4 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.ppSilver1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.ppSilver2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.ppSilver3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 5 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.perlized1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.perlized2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.perlized3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          <div className="pagination">
            <div
              className={`dot ${index === 0 && "active"}`}
              onClick={() => setIndex(0)}
            />
            <div
              className={`dot ${index === 1 && "active"}`}
              onClick={() => setIndex(1)}
            />
            <div
              className={`dot ${index === 2 && "active"}`}
              onClick={() => setIndex(2)}
            />
          </div>
        </div>
        <div className="paper-characteristics">
          <h3 className="characteristics-heading">AVAILABLE TYPES</h3>
          <ul>
            <li>Clear</li>
            <li>White (Gloss / Matte)</li>
            <li>Silver</li>
          </ul>
          <h3 className="characteristics-heading">CHARACTERISTICS</h3>
          <ul>
            <li>Rigid</li>
            <li>High clarity (clear)</li>
          </ul>
          <h3 className="characteristics-heading">SUITABLE FOR</h3>
          <ul>
            <li>Flat Surfaces</li>
            <li>Non - Squeezable Packaging</li>
          </ul>
        </div>
      </div>
    </StyledFilm>
  )
}

export default Film
