import React, { useState } from "react"
import styled from "@emotion/styled"

import coldFoiling from "../../../images/finishing/coldFoiling.svg"
import dieCut from "../../../images/finishing/dieCut.svg"
import laminating from "../../../images/finishing/laminating.svg"
import multilayer from "../../../images/finishing/multilayer.svg"
import varnish from "../../../images/finishing/varnish.svg"

const StyledFinishing = styled.div`
  margin: 0 12vw;
  padding-bottom: 5vw;
  border-bottom: 1px solid black;
  margin-bottom: 5vw;
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 5vw;
    .left {
      .heading {
        font-size: 1.5vw;
        font-weight: 600;
        color: black;
      }
    }
    .right {
      padding-right: 3vw;
      ol {
        margin-left: 0;
      }
      li {
        font-size: 1.1vw;
      }
      p {
        font-size: 1.1vw;
      }
    }
  }
  .diagram {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    .item {
      text-align: center;
      .image-wrapper {
        height: 6vw;
        margin-bottom: 1.5vw;
        img {
          height: 6vw;
        }
        .varnish {
          height: 4vw;
          transform: translateY(1.5vw);
        }
      }
      .caption {
      }
    }
  }
`

const Finishing = () => {
  const [selected, setSelected] = useState(0)
  return (
    <StyledFinishing>
      <div className="content">
        <div className="left">
          <h1 className="heading">Finishing Options</h1>
        </div>
        <div className="right">
          <ol>
            <li>
              The finishing process is one that is quite simple and puts
              accuracy above all the rest.
            </li>
            <li>
              Printed labels are then run through our Prati ‘slitter rewinder’
              finishing machine which enables a 100 percent accuracy through
              detailed and automated camera inspection.
            </li>
          </ol>
          <p>When everything is all done, the labels are packaged and sent.</p>
        </div>
      </div>
      <div className="diagram">
        <div className="item">
          <div className="image-wrapper">
            <img src={laminating} />
          </div>
          <div className="caption">LAMINATING</div>
        </div>
        <div className="item">
          <div className="image-wrapper">
            <img src={coldFoiling} />
          </div>
          <div className="caption">COLD FOILING</div>
        </div>
        <div className="item">
          <div className="image-wrapper">
            <img src={varnish} className="varnish" />
          </div>
          <div className="caption">VARNISH</div>
        </div>
        <div className="item">
          <div className="image-wrapper">
            <img src={multilayer} />
          </div>
          <div className="caption">MULTILAYER</div>
        </div>
        <div className="item">
          <div className="image-wrapper">
            <img src={dieCut} />
          </div>
          <div className="caption">DIE CUT</div>
        </div>
      </div>
    </StyledFinishing>
  )
}

export default Finishing
