import React from "react"
import styled from "@emotion/styled"

import automotive from "../../../images/industries/automotive.svg"
import electronic from "../../../images/industries/electronic.svg"
import foodBeverage from "../../../images/industries/food-beverage.svg"
import personalCare from "../../../images/industries/personal-care.svg"
import pharmaceutical from "../../../images/industries/pharmaceutical.svg"
import promotionLabels from "../../../images/industries/promotion-labels.svg"
import security from "../../../images/industries/security.svg"
import toys from "../../../images/industries/toys.svg"
import warningLabels from "../../../images/industries/warning-labels.svg"
import wineSpirits from "../../../images/industries/wine-spirits.svg"

const StyledIndustries = styled.div`
  padding-top: 15vh;
  background-color: black;
  min-height: 100vh;
  color: white;
  .container {
    padding: 5vw 12vw;
    .content {
      display: grid;
      grid-template-columns: 3fr 4fr;
      margin-bottom: 6vw;
      .heading {
        color: white;
        font-size: 2.1vw;
      }
      .right {
        width: 70%;
        p {
          font-size: 1.1vw;
        }
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      text-align: center;
      align-items: center;
      .image-wrapper {
        img {
          height: 5vw;
          margin: 1vw;
        }
        .caption {
          font-family: "Akzidenz Grotesk Extended";
          font-size: 1vw;
        }
      }
    }
  }
`

const Industries = () => {
  return (
    <StyledIndustries>
      <div className="container">
        <div className="content">
          <h1 className="heading">Industries We Serve</h1>
          <div className="right">
            <p>
              Our flexible approach in providing solutions for our customers has
              enabled us to grow exponentially through the years supplying
              labels to various industries such as:
            </p>
          </div>
        </div>
        <div className="images">
          <div className="image-wrapper">
            <img src={pharmaceutical}></img>
            <div className="caption-wrapper">
              <p className="caption">PHARMACEUTICAL</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={promotionLabels}></img>
            <div className="caption-wrapper">
              <p className="caption">PROMOTION LABELS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={wineSpirits}></img>
            <div className="caption-wrapper">
              <p className="caption">WINE & SPIRITS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={security}></img>
            <div className="caption-wrapper">
              <p className="caption">SECURITY</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={foodBeverage}></img>
            <div className="caption-wrapper">
              <p className="caption">FOOD & BEVERAGE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={warningLabels}></img>
            <div className="caption-wrapper">
              <p className="caption">WARNING LABELS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={automotive}></img>
            <div className="caption-wrapper">
              <p className="caption">AUTOMOTIVE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={personalCare}></img>
            <div className="caption-wrapper">
              <p className="caption">PERSONAL CARE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={electronic}></img>
            <div className="caption-wrapper">
              <p className="caption">ELECTRONIC</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={toys} style={{ width: "7vw" }}></img>
            <div className="caption-wrapper">
              <p className="caption">TOYS</p>
            </div>
          </div>
        </div>
      </div>
    </StyledIndustries>
  )
}

export default Industries
