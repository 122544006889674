import React from "react"
import styled from "@emotion/styled"
import { useMediaQuery } from "react-responsive"

import ServicesMobile from "./ServicesMobile"
import Industries from "./Industries"
import Materials from "./Materials"
import Finishing from "./Finishing"
import QualityControl from "./QualityControl"
import OrderingProcess from "./OrderingProcess"

const StyledServices = styled.div`
  min-height: 100vh;
  .products-heading {
    color: black;
    padding: 0 12vw;
    padding-top: 5vw;
    padding-bottom: 3vw;
    font-size: 1.7vw;
  }
`

const Services = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 900px)",
  })

  const isMobile = useMediaQuery({
    query: "(max-width: 900px)",
  })
  return (
    <StyledServices>
      {isDesktop && (
        <div>
          <Industries />
          <h1 className="products-heading">PRODUCTS & SERVICES</h1>
          <Materials />
          <Finishing />
          <QualityControl />
          <OrderingProcess />
        </div>
      )}
      {isMobile && (
        <div>
          <ServicesMobile />
        </div>
      )}
    </StyledServices>
  )
}

export default Services
