import React, { useState } from "react"
import styled from "@emotion/styled"

import Paper from "./Paper"
import Film from "./Film"
import paper from "../../../images/materials/materials-paper.svg"
import film from "../../../images/materials/materials-film.svg"

const StyledMaterials = styled.div`
  margin: 0 12vw;
  padding-bottom: 5vw;
  min-height: 90vh;
  border-bottom: 1px solid black;
  margin-bottom: 5vw;
  .heading {
    font-size: 1.5vw;
    font-weight: 600;
    color: black;
  }
  .subheading {
    color: #939598;
    font-size: 1.2vw;
    width: 20vw;
    margin-bottom: 3vw;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 2fr 8fr;
    .menu {
      border-right: 1px solid lightgrey;
      padding-right: 5vw;
      padding-left: 2vw;
      padding-top: 3vw;
      padding-bottom: 3vw;
      .image-wrapper {
        cursor: pointer;
        text-align: center;
        img {
          height: 9vw;
        }
        .selected {
          font-weight: 700;
          text-decoration: underline;
        }
      }
      .paper {
        margin-bottom: 3vw;
      }
    }
  }
`

const Materials = () => {
  const [selected, setSelected] = useState(0)
  return (
    <StyledMaterials>
      <h1 className="heading">Materials</h1>
      <p className="subheading">
        Quality constructions is more than materials and craftsmanship.
      </p>
      <div className="wrapper">
        <div className="menu">
          <div className="image-wrapper paper" onClick={() => setSelected(0)}>
            <img src={paper} />
            <div className={`caption ${selected === 0 ? "selected" : ""}`}>
              PAPER
            </div>
          </div>
          <div className="image-wrapper" onClick={() => setSelected(1)}>
            <img src={film} />
            <div className={`caption ${selected === 1 ? "selected" : ""}`}>
              FILM
            </div>
          </div>
        </div>
        <div className="content">
          {selected === 0 && <Paper />}
          {selected === 1 && <Film />}
        </div>
      </div>
    </StyledMaterials>
  )
}

export default Materials
