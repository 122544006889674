import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const StyledPaper = styled.div`
  color: #6d6e71;
  .heading {
    font-size: 1.2rem;
    font-weight: 600;
    color: #444444;
  }
  .type-heading {
    color: #444444;
    font-size: 1rem;
    font-family: "Akzidenz Grotesk Extended";
  }
  .characteristics-heading {
    color: #444444;
    font-size: 1rem;
    font-family: "Akzidenz Grotesk Extended";
  }
  .paper-image {
    margin-bottom: 1rem;
    position: relative;
    img {
      margin: 0;
    }
    .pagination {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .dot {
        margin: 0.3rem;
        background-color: transparent;
        border-radius: 50%;
        border: solid 1px white;
        width: 9px;
        height: 9px;
        padding: 0;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        background-color: white;
      }
    }
  }
  ul {
    list-style-type: square;
    .active {
      font-weight: 600;
      color: #444444;
      text-decoration: underline;
    }
  }
`

const Paper = () => {
  const [selected, setSelected] = useState(0)
  const [index, setIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      hgp1: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hgp2: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hgp3: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine1: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine2: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine3: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat1: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-1.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat2: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-2.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat3: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-3.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledPaper>
      <h1 className="heading">PAPER MATERIALS</h1>
      <div className="paper-image">
        {selected === 0 && (
          <div>
            {index === 0 && (
              <Image
                fluid={data.hgp1.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 1 && (
              <Image
                fluid={data.hgp2.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 2 && (
              <Image
                fluid={data.hgp3.childImageSharp.fluid}
                className="image"
              />
            )}
          </div>
        )}
        {selected === 1 && (
          <div>
            {index === 0 && (
              <Image
                fluid={data.rafwine1.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 1 && (
              <Image
                fluid={data.rafwine2.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 2 && (
              <Image
                fluid={data.rafwine3.childImageSharp.fluid}
                className="image"
              />
            )}
          </div>
        )}
        {selected === 2 && (
          <div>
            {index === 0 && (
              <Image
                fluid={data.semicoat1.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 1 && (
              <Image
                fluid={data.semicoat2.childImageSharp.fluid}
                className="image"
              />
            )}
            {index === 2 && (
              <Image
                fluid={data.semicoat3.childImageSharp.fluid}
                className="image"
              />
            )}
          </div>
        )}
        <div className="pagination">
          <div
            className={`dot ${index === 0 ? "active" : ""}`}
            onClick={() => setIndex(0)}
          />
          <div
            className={`dot ${index === 1 ? "active" : ""}`}
            onClick={() => setIndex(1)}
          />
          <div
            className={`dot ${index === 2 ? "active" : ""}`}
            onClick={() => setIndex(2)}
          />
        </div>
      </div>
      <ul>
        <li
          className={selected === 0 ? "active" : ""}
          onClick={() => setSelected(0)}
        >
          HGP
        </li>
        <li
          className={selected === 1 ? "active" : ""}
          onClick={() => setSelected(1)}
        >
          RAFWINE
        </li>
        <li
          className={selected === 2 ? "active" : ""}
          onClick={() => setSelected(2)}
        >
          SEMICOAT
        </li>
      </ul>
      <h2 className="characteristics-heading">CHARACTERISTICS:</h2>
      <ul>
        <li>Easy Printability</li>
        <li>Lower Cost</li>
        <li>Versatile</li>
      </ul>
    </StyledPaper>
  )
}

export default Paper
