import React from "react"
import styled from "@emotion/styled"

import MaterialsMobile from "./MaterialsMobile"
import automotive from "../../../images/industries/automotive.svg"
import electronic from "../../../images/industries/electronic.svg"
import foodBeverage from "../../../images/industries/food-beverage.svg"
import personalCare from "../../../images/industries/personal-care.svg"
import pharmaceutical from "../../../images/industries/pharmaceutical.svg"
import promotionLabels from "../../../images/industries/promotion-labels.svg"
import security from "../../../images/industries/security.svg"
import toys from "../../../images/industries/toys.svg"
import warningLabels from "../../../images/industries/warning-labels.svg"
import wineSpirits from "../../../images/industries/wine-spirits.svg"
import coldFoiling from "../../../images/finishing/coldFoiling.svg"
import dieCut from "../../../images/finishing/dieCut.svg"
import laminating from "../../../images/finishing/laminating.svg"
import multilayer from "../../../images/finishing/multilayer.svg"
import varnish from "../../../images/finishing/varnish.svg"
import accurateImage from "../../../images/quality-control/accurate-quality-control-mobile.svg"
import preciseImage from "../../../images/quality-control/precise-quality-control-mobile.svg"
import process1 from "../../../images/ordering-process-mobile/process-1.svg"
import process2 from "../../../images/ordering-process-mobile/process-2.svg"
import process3 from "../../../images/ordering-process-mobile/process-3.svg"
import process4 from "../../../images/ordering-process-mobile/process-4.svg"
import process5 from "../../../images/ordering-process-mobile/process-5.svg"
import process6 from "../../../images/ordering-process-mobile/process-6.svg"

const StyledServicesMobile = styled.div`
  color: black;
  .industries {
    padding-top: 100px;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: black;
    color: white;
    .heading {
      font-size: 1.5rem;
    }
    .images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .image-wrapper {
        width: 50%;
        box-sizing: border-box;
        text-align: center;

        img {
          padding: 0 3rem 0 3rem;
          height: 45px;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .caption {
          font-family: "Akzidenz Grotesk Extended";
          font-size: 0.7rem;
          text-align: center;
        }
      }
    }
  }
  .products-heading {
    margin: 0;
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    font-size: 1.1rem;
  }
  .materials {
  }
  .finishing {
    padding: 0 1rem;
    color: black;
    .heading {
      font-size: 2rem;
    }
  }
  .diagram {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .item {
      width: 33%;
      margin-bottom: 1rem;
      .image-wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 80px;
          margin: 0;
        }
        .varnish {
          height: 50px;
        }
        .die-cut {
          height: 60px;
        }
      }
      .caption {
        text-align: center;
      }
    }
  }
  .quality-control {
    margin-top: 3rem;
    padding: 0 1rem;
    .heading {
      font-size: 2rem;
      margin-bottom: 4rem;
    }
    .subheading {
      font-family: "Akzidenz Grotesk Extended";
      font-weight: 400;
      font-size: 1.25rem;
      text-align: center;
    }
    .description {
      font-family: "Akzidenz Grotesk Extended";
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
    }
  }
  .ordering-process {
    background-color: #f1f2f2;
    padding: 1rem;
    padding-top: 2rem;
    .heading {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
    .diagram {
      display: flex;
      flex-direction: column;
      .step {
        margin-bottom: 2rem;
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        img {
          height: 85px;
        }
        .content {
          .heading {
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }
          ul {
            list-style: square;
            color: #555555;
          }
        }
      }
    }
    .journey {
      color: #555555;
    }
  }
`

const ServicesMobile = () => {
  return (
    <StyledServicesMobile>
      <div className="industries">
        <h1 className="heading">Industries We Serve</h1>
        <p>
          Our flexible approach in providing solutions for our customers has
          enabled us to grow exponentially through the years supplying labels to
          various industries such as:
        </p>
        <div className="images">
          <div className="image-wrapper">
            <img src={pharmaceutical}></img>
            <div className="caption-wrapper">
              <p className="caption">PHARMACEUTICAL</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={promotionLabels}></img>
            <div className="caption-wrapper">
              <p className="caption">PROMOTION LABELS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={wineSpirits}></img>
            <div className="caption-wrapper">
              <p className="caption">WINE & SPIRITS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={security}></img>
            <div className="caption-wrapper">
              <p className="caption">SECURITY</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={foodBeverage}></img>
            <div className="caption-wrapper">
              <p className="caption">FOOD & BEVERAGE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={warningLabels}></img>
            <div className="caption-wrapper">
              <p className="caption">WARNING LABELS</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={automotive}></img>
            <div className="caption-wrapper">
              <p className="caption">AUTOMOTIVE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={personalCare}></img>
            <div className="caption-wrapper">
              <p className="caption">PERSONAL CARE</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={electronic}></img>
            <div className="caption-wrapper">
              <p className="caption">ELECTRONIC</p>
            </div>
          </div>
          <div className="image-wrapper">
            <img src={toys}></img>
            <div className="caption-wrapper">
              <p className="caption">TOYS</p>
            </div>
          </div>
        </div>
      </div>
      <h1 className="products-heading">PRODUCTS & SERVICES</h1>
      <div className="materials">
        <MaterialsMobile />
      </div>
      <div className="finishing">
        <h1 className="heading">Finishing Options</h1>
        <ol>
          <li>
            The finishing process is one that is quite simple and puts accuracy
            above all the rest.
          </li>
          <li>
            Printed labels are then run through our Prati ‘slitter rewinder’
            finishing machine which enables a 100 percent accuracy through
            detailed and automated camera inspection.
          </li>
        </ol>
        <p>When everything is all done, the labels are packaged and sent.</p>
        <div className="diagram">
          <div className="item">
            <div className="image-wrapper">
              <img src={laminating} />
            </div>
            <div className="caption">LAMINATING</div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={coldFoiling} />
            </div>
            <div className="caption">COLD FOILING</div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={varnish} className="varnish" />
            </div>
            <div className="caption">VARNISH</div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={multilayer} />
            </div>
            <div className="caption">MULTILAYER</div>
          </div>
          <div className="item">
            <div className="image-wrapper">
              <img src={dieCut} className="die-cut" />
            </div>
            <div className="caption">DIE CUT</div>
          </div>
        </div>
      </div>
      <div className="quality-control">
        <h1 className="heading">Quality Control</h1>
        <h2 className="subheading">ACCURATE</h2>
        <h3 className="description">Color Reproduction</h3>
        <div>
          <img src={accurateImage} />
        </div>
        <h2 className="subheading">PRECISE</h2>
        <h3 className="description">Quality Inspection</h3>
        <div>
          <img src={preciseImage} />
        </div>
      </div>
      <div className="ordering-process">
        <h1 className="heading">Ordering Process</h1>
        <div className="diagram">
          <div className="step">
            <img src={process1} />
            <div className="content">
              <div className="heading">GET A QUOTE</div>
              <ul>
                <li>Choose your Material</li>
                <li>Choose your Finishing</li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
          <div className="step">
            <img src={process2} />
            <div className="content">
              <div className="heading">DESIGN FILE</div>
              <ul>
                <li>Adobe Illustrator</li>
                <li>Adobe Photoshop</li>
                <li>PDF</li>
                <li>Corel Draw</li>
              </ul>
            </div>
          </div>
          <div className="step">
            <img src={process3} />
            <div className="content">
              <div className="heading">ARTWORK</div>
              <ul>
                <li>Text</li>
                <li>Design</li>
                <li>Layout</li>
              </ul>
            </div>
          </div>
          <div className="step">
            <img src={process4} />
            <div className="content">
              <div className="heading">SAMPLE</div>
              <ul>
                <li>Proof on Spot (Recommended)</li>
                <li>Digital Proof</li>
              </ul>
            </div>
          </div>
          <div className="step">
            <img src={process5} />
            <div className="content">
              <div className="heading">PRODUCTION</div>
              <ul>
                <li>Printing</li>
                <li>Inspection</li>
                <li>Packing</li>
              </ul>
            </div>
          </div>
          <div className="step">
            <img src={process6} />
            <div className="content">
              <div className="heading">DELIVERY</div>
              <ul>
                <li>Labels Delivered to your location</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="journey">
          <ol>
            <li>
              Our process starts by reviewing our client’s initial designs for
              text proofing and colour management purposes, before printing the
              final design through our Epson Digital Printer.
            </li>
            <li>
              After the artwork is approved by our standards, we move on to
              produce the label plates through our Esko CDI plate-making
              machine. By doing the plate designs in-house, we are able to offer
              a shorter wait time of up to 2 to 3 hours and better security of
              the client’s designs, as we do not hand them to a third party.
            </li>
            <li>
              The plates are then printed through our two Gallus Flexo printing
              machines, which offers self-lamination, screen printing, cold
              foiling, multilayering of materials and varnish.
            </li>
          </ol>
        </div>
      </div>
    </StyledServicesMobile>
  )
}

export default ServicesMobile
