import React, { useState } from "react"
import styled from "@emotion/styled"

import Paper from "./Paper"
import Film from "./Film"
import paper from "../../../../images/materials/materials-paper.svg"
import film from "../../../../images/materials/materials-film.svg"

const StyledMaterialsMobile = styled.div`
  padding: 0 1rem;
  .heading {
    font-size: 2rem;
  }
  .material-selections {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .material-selection {
      text-align: center;
      width: 50%;
      img {
        height: 6rem;
        margin: 0;
      }
      &.active {
        p {
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
`

const MaterialsMobile = () => {
  const [selectedMaterial, setSelectedMaterial] = useState(0)
  return (
    <StyledMaterialsMobile>
      <h1 className="heading">Materials</h1>
      <p>Quality constructions is more than materials and craftsmanship.</p>
      <div className="material-selections">
        <div
          className={`material-selection ${selectedMaterial === 0 && "active"}`}
          onClick={() => setSelectedMaterial(0)}
        >
          <img src={paper} />
          <p>PAPER</p>
        </div>
        <div
          className={`material-selection ${selectedMaterial === 1 && "active"}`}
          onClick={() => setSelectedMaterial(1)}
        >
          <img src={film} />
          <p>FILM</p>
        </div>
      </div>
      <div className="content-wrapper">
        {selectedMaterial === 0 && <Paper />}
        {selectedMaterial === 1 && <Film />}
      </div>
    </StyledMaterialsMobile>
  )
}

export default MaterialsMobile
