import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const StyledPaper = styled.div`
  padding: 2.5vw 2.5vw;
  padding-bottom: 0;
  padding-right: 0;
  .paper-heading {
    color: #6d6e71;
    font-size: 1.3vw;
    font-weight: 600;
    margin-bottom: 4vw;
  }
  .paper-content {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-gap: 2vw;
    .paper-menu {
      ul {
        list-style-type: none;
        margin: 0;
        li {
          font-family: "AKZIDENZ GROTESK EXTENDED";
          font-size: 0.8vw;
          color: #a7a9ac;
          margin-bottom: 1.5vw;
          cursor: pointer;
        }
        .selected {
          color: #6d6e71;
          font-weight: 600;
          transition: 0.1s;
          text-decoration: underline;
        }
      }
    }
    .paper-image {
      position: relative;
      img {
        margin: 0;
      }
      .pagination {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .dot {
          margin: 0.3rem;
          background-color: transparent;
          border-radius: 50%;
          border: solid 1px white;
          width: 9px;
          height: 9px;
          padding: 0;
          &:hover {
            cursor: pointer;
          }
        }
        .active {
          background-color: white;
        }
      }
    }
    .paper-characteristics {
      .characteristics-heading {
        font-family: "Akzidenz Grotesk Extended";
        font-size: 0.8vw;
        font-weight: 600;
        color: #6d6e71;
        margin-bottom: 1vw;
      }
      ul {
        list-style-type: square;
        color: #6d6e71;
        transform: translateX(-0.4vw);
        li {
          margin-bottom: 0.3vw;
          font-size: 0.85vw;
        }
      }
    }
  }
`

const Paper = () => {
  const [selected, setSelected] = useState(0)
  const [index, setIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      hgp1: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hgp2: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hgp3: file(
        relativePath: { eq: "materials/paper-materials/hgp/hgp-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine1: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-1.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine2: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rafwine3: file(
        relativePath: { eq: "materials/paper-materials/rafwine/rafwine-3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat1: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-1.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat2: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-2.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      semicoat3: file(
        relativePath: {
          eq: "materials/paper-materials/semicoat/semicoat-3.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledPaper>
      <h1 className="paper-heading">PAPER MATERIALS</h1>
      <div className="paper-content">
        <div className="paper-menu">
          <ul>
            <li
              className={selected === 0 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(0)
              }}
            >
              HGP
            </li>
            <li
              className={selected === 1 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(1)
              }}
            >
              RAFWINE
            </li>
            <li
              className={selected === 2 ? "selected" : ""}
              onClick={() => {
                setIndex(0)
                setSelected(2)
              }}
            >
              SEMICOAT
            </li>
          </ul>
        </div>
        <div className="paper-image">
          {selected === 0 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.hgp1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.hgp2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.hgp3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 1 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.rafwine1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.rafwine2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.rafwine3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          {selected === 2 && (
            <div>
              {index === 0 && (
                <Image
                  fluid={data.semicoat1.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 1 && (
                <Image
                  fluid={data.semicoat2.childImageSharp.fluid}
                  className="image"
                />
              )}
              {index === 2 && (
                <Image
                  fluid={data.semicoat3.childImageSharp.fluid}
                  className="image"
                />
              )}
            </div>
          )}
          <div className="pagination">
            <div
              className={`dot ${index === 0 && "active"}`}
              onClick={() => setIndex(0)}
            />
            <div
              className={`dot ${index === 1 && "active"}`}
              onClick={() => setIndex(1)}
            />
            <div
              className={`dot ${index === 2 && "active"}`}
              onClick={() => setIndex(2)}
            />
          </div>
        </div>
        <div className="paper-characteristics">
          <h3 className="characteristics-heading">CHARACTERISTICS</h3>
          <ul>
            <li>Easy Printability</li>
            <li>Lower Cost</li>
            <li>Versatile</li>
          </ul>
        </div>
      </div>
    </StyledPaper>
  )
}

export default Paper
